import axios, { InternalAxiosRequestConfig, AxiosRequestConfig } from 'axios';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
// config
import { HOST_API_LEGACY, HOST_API, FIREBASE_API } from 'src/config-global';
import { paths } from 'src/routes/paths';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { initializeApp } from 'firebase/app';
// utils
// import SnackbarUtils from 'src/utils/snackbar-utils-configurator';
import {
  localStorageGetItem,
  localStorageSetItem,
  localStorageRemoveItem,
  AUTH_STORAGE_KEYS,
} from './storage-available';

// import { toSnakeCaseKeys } from './misc';

// ----------------------------------------------------------------------

export const endpoints = {
  auth: {
    login: '/auth/login',
    refreshToken: '/auth/refresh-token',
    passwords: '/auth/passwords',
    firebase: {
      loginWithPassword: '/auth/login/new',
    },
  },
  admin: {
    clients: '/admin/clients',
    clientsExcel: '/admin/clients-excel',
    ambassadorClients: '/admin/client_referrals_stat',
    clientReferrals: '/admin/client_referrals',
    agent: '/admin/agents',
    clientRegions: '/admin/client_regions',
    overlay: {
      root: '/admin/overlay',
      activePDF: '/admin/overlay/active/pdf',
    },
    overview: {
      logistics: '/admin/dashboard/logistica-orders',
      administration: '/admin/dashboard/administration',
      currentMonthStats: '/admin/dashboard/current-month-statistics',
      logisticsCsv: '/admin/logistics/courier/csv/download',
      logisticsCsvEmail: '/admin/logistics/couriers/reports/delivery-email-csv',
    },
    orders: {
      client_orders: '/admin/client_orders',
      notifyStorageAboutClientOrder: (order_code: string) =>
        `/admin/client_orders/${order_code}/notify_storage`,
      proFormaClientOrder: (order_code: string) => `/admin/client_orders/${order_code}/proforma`,
      producer_orders: '/admin/producer_orders',
      updateClientOrderProduct: (order_code: string, id: string) =>
        `/admin/client_orders/${order_code}/client_order_products/${id}`,
      createClientOrderProduct: (order_code: string) =>
        `/admin/client_orders/${order_code}/client_order_products`,
      notifyStorageAboutProducerOrder: (order_code: string) =>
        `/admin/producer_orders/${order_code}/notify_storage`,
      addAvailability: (order_code: string) =>
        `/admin/producer_orders/${order_code}/add-availability`,
    },
    courier: '/admin/courier',
    products: {
      root: '/admin/products',
      allProducts: '/admin/products',
      pdf: '/admin/products/pdf',
      reservation: (sku: string) => `/admin/products/${sku}/reservations`,
      reservations: (sku: string, client_slug: string) =>
        `/admin/products/${sku}/reservations/${client_slug}`,
      grapeTypes: '/admin/products/grape_types',
      discount: (sku: string) => `/admin/products/${sku}/discounts`,
      deleteDiscounts: (id: string, product_id: string) =>
        `/admin/products/${product_id}/discounts/${id}`,
    },
    wineTypes: '/admin/wine_types',
    producers: '/admin/producers',
    producer: '/admin/producer',
    users: '/admin/users',
    statistics: {
      root: '/admin/stats',
      annualStats: {
        yoy: '/admin/stats/yoy',
      },
      productStats: '/admin/stats/product_stats',
      updateLogisticsCost: '/admin/stats/update_logistics_cost',
      profitability: 'admin/stats/profitability',
      bottles_flow: 'admin/stats/bottles-flow',
    },
    reservations: '/admin/reservations',
    tastings: '/admin/tastings',
    createClient: (slug: string) => `/admin/tastings/${slug}/tasting-clients`,
    deleteClient: (params: { slug: string; clientSlug: string }) =>
      `/admin/tastings/${params.slug}/tasting-clients/${params.clientSlug}`,
    createProduct: (slug: string) => `/admin/tastings/${slug}/tasting-products`,
    deleteProduct: (params: { slug: string; sku: string }) =>
      `/admin/tastings/${params.slug}/tasting-products/${params.sku}`,
  },
  customer: {
    payment_status: '/customer/payment-status',
    producers: { root: '/customer/producers', countries: '/customer/producers/countries' },
    products: {
      root: '/customer/products',
      volumes: '/customer/products/volumes',
      grape_types: '/customer/products/grape_types',
      wine_types: '/customer/products/wine_types',
      pricing: '/customer/products/pricing',
    },
    client_orders: {
      root: '/customer/client_orders',
      current: '/customer/client_orders/current',
      current_order: '/customer/client_orders/currentOrder',
      details: (order_code: string) => `/customer/client_orders/${order_code}/new`,
    },
    overlay: {
      active: '/customer/overlay/active',
      fallback: '/customer/overlay/fallback',
      mark_seen: '/customer/overlay',
      client_details: '/customer/client-details',
    },
    cart_products: {
      root: '/customer/cart_products',
      checkout: '/customer/checkout',
    },
    clients: '/customer/clients',
  },
  utils: {
    files: {
      link: '/utils/files/link/create',
    },
  },
  public: {
    clientPdfDownload: (token: string) => `public/products/pdf/${token}/download`,
    generic: `public/products/pdf/download/generic`,
  },
};

export const endpointsLegacy = {
  auth: {
    login: '/auth/login',
    refreshToken: '/auth/refresh-token',
  },
  admin: {
    clients: '/admin/clients',
    clientReferrals: '/admin/client_referrals',
    clientRegions: '/admin/client_regions',
    orders: {
      client_orders: '/admin/client_orders',
      producer_orders: '/admin/producer_orders',
    },
    products: {
      root: '/admin/products',
      allProducts: '/admin/products',
      producers: '/admin/producers',
      reservation: (sku: string) => `admin/products/${sku}/reservations`,
      reservations: (sku: string, client_slug: string) =>
        `admin/products/${sku}/reservations/${client_slug}`,
      grapeTypes: '/admin/products/grape_types',
      discount: (sku: string) => `admin/products/${sku}/discounts`,
      deleteDiscounts: (id: string, product_id: string) =>
        `admin/products/${product_id}/discounts/${id}`,
    },
    producers: '/admin/producers',
    users: '/admin/users',
    statistics: {
      root: '/admin/stats',
      saleStats: '/admin/sale-stats',
      bestSellingProductsStats: '/admin/best-selling-sale-stats',
      annualStats: {
        yoy: '/admin/stats/yoy',
      },
      annualStat: '/admin/stats/annual-stat',
      wineType: '/admin/stats/wine-type',
      getYoyStats: '/admin/stats/get-yoy-stat',
      productStats: '/admin/product_stats',
      updateLogisticsCost: '/admin/stats/update_logistics_cost',
      profitability: 'admin/stats/profitability',
    },
    reservations: '/admin/reservations',
    tastings: '/admin/tastings',
  },
  customer: {
    producers: { root: '/customer/producers', countries: '/customer/producers/countries' },
    products: {
      root: '/customer/products',
      volumes: '/customer/products/volumes',
      grape_types: '/customer/products/grape_types',
      wine_types: '/customer/products/wine_types',
    },
    client_orders: {
      root: '/customer/client_orders',
      current: '/customer/client_orders/current',
      current_order: '/customer/client_orders/currentOrder',
    },
    cart_products: {
      root: '/customer/cart_products',
    },
  },
};

// ----------------------------------------------------------------------

const createAxiosInstance = ({
  baseURL,
  isLegacyURL = false,
}: {
  baseURL: string;
  isLegacyURL?: boolean;
}) => {
  const tokenKey = isLegacyURL
    ? AUTH_STORAGE_KEYS.accessTokenLegacy
    : AUTH_STORAGE_KEYS.accessToken;

  // const refreshTokenKey = isLegacyURL
  //   ? AUTH_STORAGE_KEYS.refreshTokenLegacy
  //   : AUTH_STORAGE_KEYS.refreshToken;
  // const refreshToken = localStorageGetItem(refreshTokenKey);

  // const refreshTokenPath = isLegacyURL
  //   ? endpointsLegacy.auth.refreshToken
  //   : endpoints.auth.refreshToken;

  const customAxiosInstance = axios.create({
    baseURL,
    headers: {
      'Content-Type': 'application/json',
    },
  });

  const firebaseApp = initializeApp(FIREBASE_API);

  const AUTH = getAuth(firebaseApp);

  customAxiosInstance.interceptors.request.use(
    (config: InternalAxiosRequestConfig) => {
      const token = localStorageGetItem(tokenKey);

      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }

      return config;
    },
    (error) => Promise.reject(error),
  );

  const fetchCurrentUser = () =>
    new Promise((resolve, reject) => {
      const unsubscribe = onAuthStateChanged(
        AUTH,
        (user: any) => {
          unsubscribe();
          resolve(user);
        },
        reject,
      );
    });

  const refreshAuthToken = async (failedRequest: any) => {
    try {
      const userData = JSON.parse(localStorageGetItem(AUTH_STORAGE_KEYS.user) || '{}');

      if (!userData) {
        return await Promise.reject(failedRequest);
      }

      // const { data: res } = await customAxiosInstance.post(`${baseURL}${refreshTokenPath}`, {
      //   refreshToken,
      // });

      const currentUser: any = await fetchCurrentUser();
      if (!currentUser) {
        throw new Error('User not authenticated');
      }
      if (currentUser) {
        const accessToken = await currentUser.getIdToken(true);
        localStorageSetItem(AUTH_STORAGE_KEYS.accessToken, accessToken);
        localStorageSetItem(AUTH_STORAGE_KEYS.refreshToken, currentUser.refreshToken);
      }

      return await Promise.resolve();
    } catch (error) {
      Object.keys(AUTH_STORAGE_KEYS).forEach((key) => {
        localStorageRemoveItem(key);
      });
      // window.location.href = `${window.location.protocol}//${window.location.hostname}${
      //   ENVIRONMENTS.dev === ENVIRONMENT ? `:${window.location.port}` : ''
      // }${paths.auth.login}`;

      window.location.href = `${paths.login}`;
      return Promise.reject(error);
    }
  };

  createAuthRefreshInterceptor(customAxiosInstance, refreshAuthToken, {
    statusCodes: [401, 403],
  });

  // customAxiosInstance.interceptors.response.use(
  //   (res) => res,
  //   (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong'),
  //   // (error) => {
  //   //   SnackbarUtils.error(`${error}`);
  //   //   return Promise.reject((error.response && error.response.data) || 'Something went wrong');
  //   // },
  // );

  return customAxiosInstance;
};

export const axiosInstanceLegacy = createAxiosInstance({
  baseURL: HOST_API_LEGACY as string,
  isLegacyURL: false,
});
const axiosInstance = createAxiosInstance({ baseURL: HOST_API as string });
export default axiosInstance;

// ----------------------------------------------------------------------

export const fetcherLegacy = async (args: string | [string, AxiosRequestConfig]) => {
  const [url, config] = Array.isArray(args) ? args : [args];

  const { data } = await axiosInstanceLegacy.get(url, { ...config });

  return data;
};

export const fetcher = async (args: string | [string, AxiosRequestConfig]) => {
  const [url, config] = Array.isArray(args) ? args : [args];

  const {
    data: { data },
  } = await axiosInstance.get(url, { ...config });

  return data;
};

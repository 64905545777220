export function fConvertDateFormat(data: string): string {
  if (data) {
    const date = new Date(data);
    const day = `0${date.getDate()}`.slice(-2);
    const month = `0${date.getMonth() + 1}`.slice(-2);
    const year = date.getFullYear();

    return `${day}-${month}-${year}`;
  }
  return '';
}

export const getMonthDays = (yearParam: number, monthParam: number): number =>
  new Date(yearParam, monthParam, 0).getDate();

export const formatYearMonthDay = (
  yearValue: number | string,
  monthValue: number,
  dayValue: number,
) =>
  `${yearValue}-${monthValue.toString().padStart(2, '0')}-${dayValue.toString().padStart(2, '0')}`;

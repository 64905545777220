import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
// components
import { SplashScreen } from 'src/components/loading-screen';

import { paths } from '../paths';

// ----------------------------------------------------------------------

// Lazy load the DownloadProductsPage component
const DownloadProductsPage = lazy(() => import('src/pages/public/download-products'));
const DownloadGenericProductsPage = lazy(
  () => import('src/pages/public/download-generic-products'),
);

// ----------------------------------------------------------------------

export const downloadRoutes = [
  {
    path: paths.root,
    element: (
      <Suspense fallback={<SplashScreen />}>
        <Outlet />
      </Suspense>
    ),
    children: [
      {
        path: paths.public.downloadProducts(':token'),
        element: <DownloadProductsPage />,
      },
      {
        path: paths.public.generic,
        element: <DownloadGenericProductsPage />,
      },
    ],
  },
];

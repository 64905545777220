import { Box } from '@mui/system';
import { ICurrentMonthStats } from 'src/types';
import { fCurrency, fPercent } from 'src/utils/format-number';
import { useLocales } from 'src/locales';
import { useState, useEffect } from 'react';
import MedianStatCard from './stats-card';

export default function CurrentMonthStatsSection({
  currentMonthData,
}: {
  currentMonthData: ICurrentMonthStats;
}) {
  const { t, currentLang } = useLocales();
  const [currLang, setCurrLang] = useState<string>(currentLang.value);

  useEffect(() => {
    setCurrLang(currentLang.value);
  }, [currentLang]);
  const formattedData = {
    ordersReceived: currentMonthData.ordersReceived,
    ordersShipped: currentMonthData.ordersShipped,
    totalBottlesSold: currentMonthData.totalBottlesSold,
    totalRevenue: fCurrency(currentMonthData.totalRevenue?.toFixed(2), currLang),
    totalMargin: fCurrency(currentMonthData.totalMargin?.toFixed(2), currLang),
    totalMarginPercentage: fPercent(currentMonthData.marginPercentage?.toFixed(2)),
  };
  const titlesMap: { [key: string]: string } = {
    ordersReceived: 'ordini_ricevuti',
    ordersShipped: 'ordini_spediti',
    totalBottlesSold: 'bottiglie',
    totalRevenue: 'ricavi',
    totalMargin: 'margine',
    totalMarginPercentage: 'margine',
  };

  const iconsMap: { [key: string]: string } = {
    ordersReceived: 'mdi:package-variant-closed',
    ordersShipped: 'mdi:truck',
    totalBottlesSold: 'mdi:bottle-soda-classic-outline',
    totalRevenue: currLang === 'en' ? 'mdi:currency-usd' : 'mdi:currency-eur',
    totalMargin: currLang === 'en' ? 'mdi:currency-usd' : 'mdi:currency-eur',
    totalMarginPercentage: 'mdi:percent',
  };

  const renderCurrentMonthStats = Object.entries(formattedData).map(([key, value]) => {
    const title = titlesMap[key] || key;
    const icon = iconsMap[key];

    return (
      <MedianStatCard
        key={key}
        value={value}
        subTitle={t(title === 'totalMarginPercentage' ? 'totalMargin' : title)}
        icon={icon}
      />
    );
  });

  return (
    <Box
      gap={2}
      display="grid"
      gridTemplateColumns={{
        xs: 'repeat(1, 1fr)',
        sm: 'repeat(2, 1fr)',
        md: 'repeat(3, 1fr)',
        lg: 'repeat(6, 1fr)',
      }}
      mt={4}
      mb={4}
    >
      {renderCurrentMonthStats}
    </Box>
  );
}

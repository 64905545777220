export const CONSTANT_VARIABLES = {
  ADMIN: 'admin',
  CUSTOMER: 'customer',
  DEVELOP: 'develop',
  PRODUCTION: 'production',
  STAGE: 'stage',
  USER_STORAGE_KEY: 'user',
};

export const XPITALITY_CRED = {
  NAME: 'Xpitality Srl',
};
